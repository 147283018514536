// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-components-index-contact-js": () => import("./../../../src/pages/components_index/Contact.js" /* webpackChunkName: "component---src-pages-components-index-contact-js" */),
  "component---src-pages-components-index-main-js": () => import("./../../../src/pages/components_index/Main.js" /* webpackChunkName: "component---src-pages-components-index-main-js" */),
  "component---src-pages-components-index-our-value-js": () => import("./../../../src/pages/components_index/OurValue.js" /* webpackChunkName: "component---src-pages-components-index-our-value-js" */),
  "component---src-pages-components-index-portfolio-js": () => import("./../../../src/pages/components_index/Portfolio.js" /* webpackChunkName: "component---src-pages-components-index-portfolio-js" */),
  "component---src-pages-components-index-services-js": () => import("./../../../src/pages/components_index/Services.js" /* webpackChunkName: "component---src-pages-components-index-services-js" */),
  "component---src-pages-components-index-stats-js": () => import("./../../../src/pages/components_index/Stats.js" /* webpackChunkName: "component---src-pages-components-index-stats-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

